import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/index.jsx";
import Body from '../../components/Body';
import Headline from '../../components/Body/Headline';
import Donate from '../../components/Donate';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PressReleaseList from '../../components/PressReleaseList';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Body withoutHero mdxType="Body">
  <Headline level="1" standalone mdxType="Headline">Press Releases</Headline>
  <PressReleaseList mdxType="PressReleaseList" />
  <Headline level="4" standalone style={{
        cursor: 'pointer',
        display: 'none'
      }} mdxType="Headline">Subscribe Press Newsletter</Headline>
    </Body>
    <Donate text="Donate" isVisible mdxType="Donate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      